import React from 'react'
import { Link } from 'gatsby'
import { Root } from 'templates/layout'
import { DesignRow, Heading1, Heading3, Heading4, RegularText } from 'components/design'
import NewsletterSignup from 'embeds/newsletter-signup'
import Pagination from './pagination'
import TagList from './tag-list'

export default function BlogListTpl({
	pageContext: { currentPage, pageCount, pathPrefix, posts, tags, activeTag },
}) {
	const postRows = []

	for (let i = 0; i < posts.length; i += 2) {
		postRows.push(
			<DesignRow
				key={`row-${i}`}
				className="mt-20"
				leftColumnWithGutter={postBlock(posts[i])}
				rightColumnWithGutter={postBlock(posts[i + 1])}
			/>
		)
	}

	return (
		<Root seoFields={{ canonicalPathname: pathPrefix }}>
			<DesignRow
				className="mt-52"
				leftColumn={
					<>
						{activeTag === 'All' ? (
							<Heading4>Blog</Heading4>
						) : (
							<Link to="/blog/" className="accent-hover">
								<Heading4>Blog</Heading4>
							</Link>
						)}
						<Heading1 className="mt-2">Stories from the&nbsp;studio</Heading1>
					</>
				}
			/>
			<TagList activeTag={activeTag} tags={tags} />
			{[...postRows]}
			<Pagination
				pageCount={pageCount}
				currentPage={currentPage}
				pathPrefix={pathPrefix}
			/>

			<NewsletterSignup />
		</Root>
	)
}

const postBlock = (post) =>
	post ? (
		<Link to={`/blog/${post.uid}/`} className="accent-hover">
			<div
				className="min-h-[10rem] w-full bg-indigo-100 bg-cover"
				style={{
					aspectRatio: '2 / 1',
					backgroundImage: `url("${post.data.cover_image.fluid.src}")`,
				}}
			></div>
			<p className="mt-4 text-sm text-black opacity-40">
				{post.first_publication_date}
			</p>
			<Heading3 className="mt-4">{post.data.title}</Heading3>
			<RegularText className="mt-4 text-black">
				{post.data.list_paragraph}
			</RegularText>
		</Link>
	) : null
