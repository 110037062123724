import React from 'react'
import { Link } from 'gatsby'
import { DesignRow, Heading5 } from 'components/design'

export default function TagList({ activeTag, tags }) {
	return (
		<DesignRow
			className="mt-10"
			columnToColumn={
				<div className="flex flex-col md:flex-row">
					<Heading5 className="mr-6 py-2">Filter:</Heading5>
					<div className="flex flex-wrap gap-x-8 md:gap-x-12">
						{activeTag === 'All' ? (
							<span className="inner-link-current py-2">All</span>
						) : (
							<Link className="inner-link py-2" to={`/blog/`}>
								All
							</Link>
						)}
						{tags.map((tag) =>
							activeTag === tag.name ? (
								<span className="inner-link-current py-2" key={tag.slug}>
									{tag.name}
								</span>
							) : (
								<Link
									className="inner-link py-2"
									to={`/blog/tag/${tag.slug}/`}
									key={tag.slug}
								>
									{tag.name}
								</Link>
							)
						)}
					</div>
				</div>
			}
		/>
	)
}
