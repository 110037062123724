import React from 'react'

const RightArrow = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		className="h-full w-full fill-current"
	>
		<g fill="none" fillRule="evenodd">
			<path
				d="M0 0H24V24H0z"
				transform="translate(-276.000000, -414.000000) translate(20.000000, 414.000000) translate(256.000000, 0.000000)"
			/>
		</g>
		<g>
			<path
				fillRule="nonzero"
				d="M12 5.4L11.151 6.249 16.491 11.588 5.4 11.588 5.4 12.788 16.491 12.788 11.151 18.128 12 18.976 18.6 12.188z"
				transform="translate(-276.000000, -414.000000) translate(20.000000, 414.000000) translate(256.000000, 0.000000)"
			/>
		</g>
	</svg>
)

export default RightArrow
