import React from 'react'
import { Link } from 'gatsby'
import RightArrow from 'svg/right-arrow'

export default function Pagination({ currentPage, pageCount, pathPrefix }) {
	const buildPath = (num) => (num === 1 ? pathPrefix : `${pathPrefix}${num}/`)
	const leftArrow = (
		<div className="mr-5 flex h-10 w-10 rotate-180 items-center justify-center">
			{currentPage === 1 ? (
				<div className="opacity-50">
					<RightArrow />
				</div>
			) : (
				<Link
					to={buildPath(currentPage - 1)}
					className="accent-hover"
					title="Previous page"
				>
					<RightArrow />
				</Link>
			)}
		</div>
	)
	const rightArrow = (
		<div className="ml-5 flex h-10 w-10 items-center justify-center">
			{currentPage === pageCount ? (
				<div className="opacity-50">
					<RightArrow />
				</div>
			) : (
				<Link
					to={buildPath(currentPage + 1)}
					className="accent-hover"
					title="Next page"
				>
					<RightArrow />
				</Link>
			)}
		</div>
	)
	const activePageNo = (pageNo) => (
		<div
			key={pageNo}
			className="flex h-10 w-10 items-center justify-center bg-black bg-opacity-10 leading-none"
		>
			{pageNo}
		</div>
	)
	const dotsPageNo = (pageNo) => (
		<div
			key={pageNo}
			className="flex h-10 w-10 items-center justify-center leading-none"
		>
			...
		</div>
	)
	const linkPageNo = (pageNo) => (
		<Link
			key={pageNo}
			className="accent-hover flex h-10 w-10 items-center justify-center leading-none"
			to={buildPath(pageNo)}
		>
			{pageNo}
		</Link>
	)

	return (
		<div className="mt-20 flex justify-center text-sm">
			{leftArrow}
			{buildVisiblePages(currentPage, pageCount).map((pageNo, index) => {
				if (pageNo === 'dots') {
					return dotsPageNo(index)
				}
				return currentPage === pageNo ? activePageNo(pageNo) : linkPageNo(pageNo)
			})}
			{rightArrow}
		</div>
	)
}

// There are a maximum of 5 squares displayed.
// First, last and current pages always present.
// The rest are replaced with ... by the following algorithm to maintain consistency as possible
function buildVisiblePages(currentPage, pageCount) {
	let links
	if (pageCount <= 5) {
		links = Array.from({ length: pageCount }).map((_, index) => index + 1)
	} else {
		links = [1]
		for (let i = currentPage - 2; i <= currentPage + 2; i++) {
			links.push(i)
		}
		links.push(pageCount)
		links = links.filter(
			(number, index) =>
				number > 0 && number <= pageCount && links.indexOf(number) === index
		)

		if (links.length < 5) {
			if (currentPage > pageCount / 2) {
				links.splice(1, 0, 'dots')
			} else {
				links.splice(3, 0, 'dots')
			}
		} else if (links.length === 5) {
			if (currentPage > pageCount / 2) {
				links[1] = 'dots'
			} else {
				links[3] = 'dots'
			}
		} else if (links.length === 6) {
			if (currentPage > pageCount / 2) {
				links[1] = 'dots'
				links.splice(2, 1)
			} else {
				links[3] = 'dots'
				links.splice(4, 1)
			}
		} else {
			links.splice(1, 2, 'dots')
			links.splice(3, 2, 'dots')
		}
	}

	return links
}
