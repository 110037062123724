import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { DesignRow, Heading2, Heading3, IntenseText } from 'components/design'
import NewsletterForm from 'forms/newsletter'

export default function NewsletterSignupSection() {
	const {
		newsletter_row_1,
		newsletter_row_2,
		newsletter_row_3,
		newsletter_thank_you,
		newsletter_image_1,
		newsletter_image_2,
		newsletter_image_3,
	} = useReachOutData()

	return (
		<section
			id="newsletter-signup"
			className="relative mt-20 bg-black/5 pb-8 lg:mt-40 lg:bg-inherit lg:pb-0"
		>
			<div className="absolute top-0 left-content-step z-[-1] hidden h-full w-content-step-34 bg-black/5 lg:block" />
			<DesignRow
				leftColumnWithGutter={
					<div className="flex min-h-[28rem] flex-col justify-center lg:min-h-[40rem] lg:w-content-step-10">
						<Heading3>{newsletter_row_1}</Heading3>
						<Heading2 className="mt-6 lg:mt-10">{newsletter_row_2}</Heading2>
						<IntenseText className="mt-6">{newsletter_row_3}</IntenseText>
						<NewsletterForm thankYouMessage={newsletter_thank_you} />
					</div>
				}
				rightImage={
					<>
						<div
							className="inline-block h-full min-h-[20rem] w-1/2 bg-cover bg-center"
							style={{
								backgroundImage: `url('${newsletter_image_1.url}')`,
							}}
						/>
						<div
							className="inline-block h-full min-h-[20rem] w-1/4 bg-cover bg-center"
							style={{
								backgroundImage: `url('${newsletter_image_2.url}')`,
							}}
						/>
						<div
							className="inline-block h-full min-h-[20rem] w-1/4 bg-cover bg-center"
							style={{
								backgroundImage: `url('${newsletter_image_3.url}')`,
							}}
						/>
					</>
				}
			/>
		</section>
	)
}

const useReachOutData = () => {
	const data = useStaticQuery(graphql`
		query newsletterSectionQuery {
			prismicReachOut {
				data {
					newsletter_row_1
					newsletter_row_2
					newsletter_row_3
					newsletter_thank_you
					newsletter_image_1 {
						url
					}
					newsletter_image_2 {
						url
					}
					newsletter_image_3 {
						url
					}
				}
			}
		}
	`)

	return data.prismicReachOut.data
}
