import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IntenseText } from 'components/design'

export default function NewsletterForm({
	thankYouMessage,
	marginClasses,
	buttonClasses,
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionError, setSubmissionError] = useState(false)
	const [submissionSuccess, setSubmissionSuccess] = useState(false)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: { name: '', email: '', message: '' },
	})

	const rules = {
		email: {
			required: 'The email is required',
			pattern: {
				value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				message: 'Please provide a valid email address',
			},
		},
	}

	const submitNewsletterForm = (values) => {
		const endpoint =
				'https://europe-west3-cuub-website.cloudfunctions.net/subscribeToMailingList',
			blogEndpoint = 'https://europe-west3-cuub-website.cloudfunctions.net/zohoBlog'

		setIsSubmitting(true)

		fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		})
			.then((response) => {
				if (response.ok) {
					window.dataLayer &&
						window.dataLayer.push({ event: 'newsletter.subscription' })
				} else {
					setIsSubmitting(false)
					setSubmissionError(true)
				}
			})
			.catch((error) => {
				console.error('Error:', error)
				setIsSubmitting(false)
				setSubmissionError(true)
			})

		fetch(blogEndpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		})
			.then((response) => {
				if (response.ok) {
					setSubmissionSuccess(true)
				} else {
					setIsSubmitting(false)
					setSubmissionError(true)
				}
			})
			.catch((error) => {
				console.error('Error:', error)
				setIsSubmitting(false)
				setSubmissionError(true)
			})
	}

	return (
		<>
			<form
				onSubmit={handleSubmit(submitNewsletterForm)}
				className={`transition-500 transition-all ${
					submissionSuccess ? 'hidden' : ''
				}`}
			>
				<div className={marginClasses ? marginClasses : 'relative mt-8 mb-12'}>
					<input
						className="w-full border-0 border-b bg-transparent px-0 pb-2 focus:outline-none focus:ring-black focus:ring-opacity-0"
						type="email"
						placeholder="Your business email"
						{...register('email', rules.email)}
					/>
					<p className="absolute bottom-2 right-0 text-sm italic text-accent">
						{errors?.email ? errors.email.message : ''}
					</p>
				</div>

				<button
					className={
						buttonClasses
							? buttonClasses
							: `cta-black cta-black-wide relative flex w-full items-center bg-black px-4 py-5 text-xs font-semibold uppercase leading-6 tracking-px text-white disabled:cursor-not-allowed disabled:bg-opacity-50 lg:w-3/4 lg:items-baseline lg:px-6 lg:text-sm lg:leading-6`
					}
					type="submit"
					disabled={isSubmitting || Object.keys(errors).length > 0}
				>
					Subscribe
				</button>

				<p
					className={`mt-8 text-lg font-bold text-accent ${
						submissionError ? 'block' : 'hidden'
					}`}
				>
					Sorry for the technical difficulties. Please contact us via
					hello@cuubstudio.com.
				</p>
			</form>
			<IntenseText
				className={`transition-500 mt-12 transition-all ${
					submissionSuccess ? '' : 'hidden'
				}`}
			>
				{thankYouMessage}
			</IntenseText>
		</>
	)
}
